import React, { lazy,Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import Editpage from './pages/editpage/editpage'

const App = lazy(() => import('./pages/app/App'));
ReactDOM.render(
  <HashRouter>
  <Suspense fallback={<div>loading...</div>}>
    <Switch>
      <Route path="/editpage/new" component={() => <Editpage></Editpage>} />
      <Route path="/editpage/:id" component={() => <Editpage></Editpage>} />
      <Route path="/:appid" component={() => <App></App>} />
      <Redirect from="/" to="/note"></Redirect>
  </Switch>
  </Suspense>
  </HashRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
