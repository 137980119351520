import { notification } from 'antd';

const openNotificationWithIcon = (type, content) => {
  notification[type]({
    message: content,
    duration: 2,
    // description:
    //   'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
  });
};

export  function notice(json) {
    let type = 'info';
    let content = 'content'
    switch (json.code){
        case -1 : type = 'warning' 
        content = json.msg
        break;
        case -2 : type = 'warning' 
        content = json.msg
        window.localStorage.removeItem('token')
        break;
        case 500 : type = 'error' 
        content = json.msg
        break;
        case 200 : type = 'success' 
        content = json.msg
        break;
        default : type = 'error' 
        content = '未知异常'
        break;
    }
    openNotificationWithIcon(type,content)
}
// const host = "http://localhost:8080"
// const host = "https://127.0.0.1:8081"
const host = ""
const apiurl ={
        login:host+"/api/login",
        logout:host+"/api/logout",
        changePwd:host+"/api/changePwd",
        getRootFolder:host+"/api/folder/getRootFolder",
        getFileByFolderId:host+"/api/folder/getFileByFolderId",

        addFolder:host+"/api/folder/add",
        deleteFolder:host+"/api/folder/delete",

        getChildrenFolders:host+"/api/folder/getChildrenFoldersById",

        getFile:host+"/api/file/get",
        addFile:host+"/api/file/add",
        updateFile:host+"/api/file/update",
        deleteFile:host+"/api/file/delete",
        uploadFile:host+"/api/file/uploadFile",
        fullTextSearch:host+"/api/file/fullTextSearch",
    }
export default apiurl;