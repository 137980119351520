import React, { Component } from "react";
import { Button, Input, Select, Form, Upload } from "antd";
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import { withRouter } from 'react-router-dom';
import './editpage.css'
import apiurl, { notice } from '../urlconfig'
import { UploadOutlined } from '@ant-design/icons';

const { Option } = Select;
class Editpage extends Component {
    editorRef = React.createRef();
    formRef = React.createRef();
    constructor(props) {
        super(props);
        // console.log(props)
        this.state={loading : false}
        this.state = { disabled: false }
        this.state = { classchoose: '分类', folderchoose: '文件夹' }
        this.state = { classList: [{ id: 1, name: 1 }], folderList: [{ id: 1, name: 1 }] }
    }
    componentDidMount() {
        if (this.props.match.params.hasOwnProperty("id")) {

            let formData = new FormData();
            formData.append('token', window.localStorage.getItem('token'));
            formData.append('id', this.props.match.params.id);
            fetch(apiurl.getFile, {
                method: 'POST',
                mode: 'cors',
                body: formData
            })
                .then((response) => response.json())
                .then((json) => {
                    if (json.code!==200){
                        notice(json)
                        return
                    }
                    this.editorRef.current.getInstance()
                        .setMarkdown(json.data.file.markdown)
                    this.getClassFolder(json.data.file.classId)
                    this.getSecondFolder(json.data.file.classId,json.data.file.folderId)
                    this.formRef.current.setFieldsValue({title:json.data.file.title})
                    // console.log("json.data.file",json.data.file)
                    return json
                })
                .then((json) => {
                    this.handleClassMenuClick(json.data.file.classId)
                    this.handleFolderMenuClick(json.data.file.folderId)
                })
                .catch((error) => console.log(error))
        } else {
            this.editorRef.current.getInstance()
                .insertText("新建文章\n")
            this.getClassFolder()
        }
    }
    getClassFolder = () => {

        let formData = new FormData();
        formData.append('token', window.localStorage.getItem('token'));
        fetch(apiurl.getRootFolder, {
            method: 'POST',
            mode: 'cors',
            body: formData
        })
            .then((response) => response.json())
            .then((json) => {
                let classList = []
                json.data.folders.forEach(item => {
                    classList.push({ id: item.id, name: item.name })
                    this.setState({ classList: classList })
                })
            })
            .catch((error) => console.log(error))
    }
    getSecondFolder = (id) => {
        
        let formData = new FormData();
        formData.append('token', window.localStorage.getItem('token'));
        formData.append('id', id);
        fetch(apiurl.getChildrenFolders, {
            method: 'POST',
            mode: 'cors',
            body: formData
        })
            .then((response) => response.json())
            .then((json) => {
                let folderList = []
                json.data.folders.forEach(item => {
                    folderList.push({ id: item.id, name: item.name })
                    this.setState({ folderList: folderList })
                })
            })
            .catch((error) => console.log(error))
    }
    handleClick = (e) => {
        let title = e.title;
        let htmlContent = this.editorRef.current.getInstance().getHTML()
        let markdownContent = this.editorRef.current.getInstance().getMarkdown()
        let classId = this.state.classchoose
        let folderId = this.state.folderchoose
        if (title === null || title === undefined || title === '') {
            notice({ code: -1, msg: '标题为空' })
            return
        }
        if (classId === null || classId === undefined || classId === 1) {
            notice({ code: -1, msg: '分类为空' })
            return
        }
        if (folderId === null || folderId === undefined || folderId === 1) {
            notice({ code: -1, msg: '文件夹为空' })
            return
        }
        if (this.props.match.params.hasOwnProperty("id")) {
            // 更新文档
            let formData = new FormData();
            formData.append('token', window.localStorage.getItem('token'));
            formData.append('title', title);
            formData.append('markdown', markdownContent);
            formData.append('html', htmlContent);
            formData.append('folderId', folderId);
            formData.append('id', this.props.match.params.id);
            fetch(apiurl.updateFile, {
                method: 'POST',
                mode: 'cors',
                body: formData
            })
                .then((response) => response.json())
                .then((json) => {
                    notice(json)
                })
                .catch((error) => console.log(error))
            this.setState({ disabled: true })
        } else{
            // 保存文档
            let formData = new FormData();
            formData.append('token', window.localStorage.getItem('token'));
            formData.append('title', title);
            formData.append('markdown', markdownContent);
            formData.append('html', htmlContent);
            formData.append('folderId', folderId);
            fetch(apiurl.addFile, {
                method: 'POST',
                mode: 'cors',
                body: formData
            })
                .then((response) => response.json())
                .then((json) => {
                    notice(json)
                })
                .catch((error) => console.log(error))
            this.setState({ disabled: true })
        }
        
    }
    handleClassMenuClick = (e) => {
        // console.log(e)
        this.setState({ classchoose: e });
        this.getSecondFolder(e)
    }
    handleFolderMenuClick = (e) => {
        // console.log(e)
        this.setState({ folderchoose: e });
    }
    props = {
        action: apiurl.uploadFile,
        data:{token:window.localStorage.getItem('token')},
        onChange({ file, fileList }) {
          if (file.status !== 'uploading') {
            // console.log(file, fileList);
          }
        },
        defaultFileList: [
          {
            uid: '1',
            name: 'xxx.png',
            status: 'done',
            response: 'Server Error 500', // custom error message to show
            url: 'http://www.baidu.com/xxx.png',
          },
          {
            uid: '2',
            name: 'yyy.png',
            status: 'done',
            url: 'http://www.baidu.com/yyy.png',
          },
          {
            uid: '3',
            name: 'zzz.png',
            status: 'error',
            response: 'Server Error 500', // custom error message to show
            url: 'http://www.baidu.com/zzz.png',
          },
        ],
      };
      customRequest = (data) => {
            // 上传文件
            this.setState({loading:true})
            let formData = new FormData();
            formData.append('token', window.localStorage.getItem('token'));
            formData.append('file', data.file);
            fetch(apiurl.uploadFile, {
                method: 'POST',
                mode: 'cors',
                body: formData
            })
                .then((response) => response.json())
                .then((json) => {
                    notice(json)
                    this.setState({loading:false})
                    this.editorRef.current.getInstance()
            .insertText("\n![]("+json.data.filePath+")\n")
                })
                .catch((error) => {
                    console.log(error)
                    this.setState({loading:false})
                    notice({code:-1, msg:'上传失败'})
                })
      }
    render() {
        return (
            <div>

                <Select defaultValue="分类" style={{ width: 120 }} onChange={this.handleClassMenuClick}>
                    {this.state.classList.map(item => {
                        return (<Option key={item.id} value={item.id}>{item.name}</Option>)
                    })}
                </Select>
                <Select defaultValue="文件夹" style={{ width: 120 }} onChange={this.handleFolderMenuClick}>
                    {this.state.folderList.map(item => {
                        return (<Option key={item.id} value={item.id}>{item.name}</Option>)
                    })}
                </Select>
                <Upload 
                // action={apiurl.uploadFile} 
                // data={{token:window.localStorage.getItem('token')}} 
                // maxCount={1}
                showUploadList={false}
                customRequest={this.customRequest}
                >
                    <Button icon={<UploadOutlined />} loading={this.state.loading}>上传图片并插入</Button>
                </Upload>
                <Form  onFinish={this.handleClick} ref={this.formRef} layout="inline" 
                 style={{display:'inline'}} >
                    <Form.Item name='title' label='标题' style={{display:'inline'}}>
                    <Input style={{ width: '200px' }}/>
                    </Form.Item>
                    <Button type="primary" htmlType="submit" disabled={this.state.disabled}>
                        保存
                        </Button>
                </Form>
                <Editor
                    initialValue="hello react editor world!"
                    previewStyle="vertical"
                    height="90vh"
                    initialEditType="markdown"
                    useCommandShortcut={true}
                    usageStatistics={false}
                    ref={this.editorRef}
                />

            </div>
        )
    }
}

export default withRouter(Editpage);